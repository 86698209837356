import(/* webpackMode: "eager" */ "/vercel/path0/apps/faucet/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/Satoshi-Variable.ttf\",\"variable\":\"--font-sans\"}],\"variableName\":\"satoshi\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-space-grotesk\"}],\"variableName\":\"spaceGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-toastify@10.0.5_react-dom@18.3.1_react@18.3.1/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-toastify@10.0.5_react-dom@18.3.1_react@18.3.1/node_modules/react-toastify/dist/ReactToastify.css");
